@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Signika+Negative:wght@300..700&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika+Negative:wght@300..700&family=Sora:wght@100..800&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
body {
  margin: 0;
}

.arkaPlan {
  width: 100%;
  display: flex;
  background-color: #1F2E8F;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
  top: 0;
  left: 0;
}
.nameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  color: #1F2E8F;
  font-weight: 700;
  font-family: "Sora", sans-serif;
}
.compName {
  position: static;
  margin: 0;
  padding: 0;
  transform: none;
  font-size: 1.2em;
}


@media screen and (max-width: 768px) {
  .arkaPlan {
    padding: 10px 0;
  }

  .nameContainer {
    padding: 5px;
    justify-content: center;
    text-align: center;
  }

  .compName {
    font-size: 0.9em;
    width: 100%;
    text-align: center;
  }


}

@media screen and (max-width: 480px) {
  .arkaPlan {
    padding: 8px 0;
  }

  .nameContainer {
    padding: 3px;
  }

  .compName {
    font-size: 0.8em;
  }


}
