@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Signika+Negative:wght@300..700&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika+Negative:wght@300..700&family=Sora:wght@100..800&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.footerContainer{
    display: flex;
    width: 100%;
    background-color: #1F2E8F;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-family: "Sora", sans-serif;
    color: #fff;
    padding: 30px 0;
    position: relative;
    bottom: 0;
    
    
}
.footerSection1{
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    margin: 0;
}
.numberFoot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.footIcon{
    font-size: 2em;
    margin-bottom: 30px;
}
.footBaslik{
    font-size: 1.4em;
}
.footBaslik > ul{
    margin: 0px; /* Alt maddeler için boşluk */
  list-style-type: square; /* Alt maddeler kare olsun */
    
}
.footerSection1 > img{
    width: 50%;
    
}
.footerSection2{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
}
.footerSection3{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.footerSection4{
    width: 20%;
    display: flex;
}
.footerSection4 > img{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.numberFoot{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px ;
   
}
.number{
    margin: 2px;
    align-items: center;
    justify-content: center;
}

/* Mobil görünüm için medya sorgusu */
@media screen and (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    padding: 20px 1px;
    gap: 30px;
  }

  .footerSection1 {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  .footerSection1 > img {
    width: 200px;
    margin-bottom: 10px;
  }

  .numberFoot {
    width: 100%;
    margin: 0;
  }

  .footIcon {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  .number {
    font-size: 1.1em;
    margin: 5px 0;
  }

  .footerSection2,
  .footerSection3 {
    width: 90%;
    text-align: center;
  }

  .footerSection2 ul,
  .footerSection3 ul {
    padding: 0;
    margin: 10px 0;
    list-style: none;
  }

  .footerSection2 li,
  .footerSection3 li {
    margin: 8px 0;
    font-size: 1em;
  }

  .footBaslik {
    font-size: 1.2em;
    margin-bottom: 10px;
    text-align: center;
  }

  .footerSection4 {
    width: 60%;
  }

  .footerSection4 > img {
    width: 100%;
    max-width: 150px;
  }
}

/* Daha küçük ekranlar için ek düzenlemeler */
@media screen and (max-width: 480px) {
  .footerContainer {
    padding: 15px 0px;
  }

  .footerSection1 > img {
    width: 150px;
  }

  .number {
    font-size: 1em;
  }

  .footBaslik {
    font-size: 1.1em;
  }

  .footerSection2 li,
  .footerSection3 li {
    font-size: 0.9em;
  }

  .footerSection4 {
    width: 50%;
  }

  .footerSection4 > img {
    max-width: 120px;
  }
}
