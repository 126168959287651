@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Signika+Negative:wght@300..700&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika+Negative:wght@300..700&family=Sora:wght@100..800&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");


.backImage > img{
    width: 100%
}
.backImage {
    position: relative;
}
.teklif_Container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 0;
    position: absolute;
    top: 280px;
    left: 200px;
    
    width: 300px;
    font-family: "Sora", sans-serif;
    font-weight: 600;
}
.basliklar{ 
    color: #1F2E8F;
    font-size: 1.3em;
    font-weight: 800;
}
.teklif_buton > button{
    margin-top: 10px;
    width: 280px;
    height: 40px;
    background-color: transparent;
    color: #292929;
    border: 1px solid #292929;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: 1em;
    cursor: pointer;
}
.teklif_buton > button:hover{
    transition: 0.3s ease-in-out;
    background-color: #1F2E8F;
    color: #fff;
    border: 1px solid #fff;
}
.anlasmali_Sirketler{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin: 0 auto;
    width: 70%;
  
}
.sirketBaslik > h1{
    color: #1F2E8F;
    font-family: "Sora", sans-serif;
    font-weight: 500;
    letter-spacing: 0.2em;
}
.sirketBaslik {
    color: #1F2E8F;
    font-family: "Sora", sans-serif;
    font-weight: 500;
    letter-spacing: 0.2em;
}
.teklifBaslik{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    color: #1F2E8F;
    font-family: "Sora", sans-serif;
    font-weight: 500;
    letter-spacing: 0.2em;
}

.sirketLogololari{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 ;
}
.sirketimg {
    width: 20%;
    display: flex;
    margin: 0px;
    align-items: center;
    justify-content: center;
}

.sirketimg img{
    width: 100%;
}

.teklif{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 70%;
    flex-wrap: wrap;
    justify-content: center;
}

.teklifContainer{
    width: auto;
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.teklifImage img{
    width: 300px;
    height: 200px;
}
.teklifKutu{
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 10px solid rgb(82, 82, 82);
}
.teklifButon > button{
    width: 250px;
    background-color: #1F2E8F;
    color: #fff;
    border: none;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: 1em;
    cursor: pointer;
    margin-top: 5px;
    height: 50px;
    text-align: center;
    flex-direction: column;
}
.teklifButon > button:hover{
    transition: 0.3s ease-in-out;
    background-color: #fff;
    color: #1F2E8F;
    border: 2px solid #1F2E8F;
    
}
.whyWorkContainer{
    width: 100%;
    background-color: #D7DDEC;
}

.whyWork{
    width: 70%;
    display: flex;
    align-items: center;
    margin: 10px 10px 0 10px;
    margin: 0 auto;
   
    flex-wrap: wrap;
    justify-content: center;
   
}

.workCard{
    width: 30%;
    display: flex;
    margin: 30px 10px;
    justify-content: center;
    box-shadow: 0 0 10px 4px rgb(214, 214, 214);
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out; /* Animasyon ekliyoruz */
    transform: perspective(1000px) translateZ(0); /* Varsayılan perspektif */
}

.workCard:hover {
    transform: perspective(1000px) translateZ(20px) scale(1.05); /* Yakınlaşma ve havaya kalkma */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2); /* Daha derin bir gölge */
  }

.workCard > img{
    width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  .backImage img {
    width: 100%;
    height: auto;
  }

  .teklif_Container {
    position: static;
    width: 90%;
    margin: 20px auto;
    padding: 0px;
    top: auto;
    left: auto;
    color: #1F2E8F;
    align-items: center;
  }

  .backImage {
    margin-bottom: -20px;
  }

  .hizmetler {
    width: 100%;
    color: #292929;
  }

  .hizmetler ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .hizmetler li {
    font-size: 1em;
    margin: 10px 0;
    text-align: center;
    color: #1F2E8F;
  }

  .teklif_buton {
    width: 100%;
  }

  .teklif_buton > button {
    width: 100%;
    margin: 20px 0;
  }

  .basliklar p {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 15px;
  }

  .teklifBaslik h1 {
    font-size: 1.5em;
    text-align: center;
    margin: 30px 0 20px;
  }

  /* Anlaşmalı şirketler bölümü */
  .anlasmali_Sirketler {
    width: 100%;
    padding: 0px;
  }

  .sirketLogololari {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 0px;
    width: 100%;
  }

  .sirketimg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .sirketimg img {
    width: 100%;
    height: auto;
  }

  /* Teklif kartları bölümü */
  .teklif {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 15px;
  }

  .teklifContainer {
    width: 100%;
    margin: 0;
  }

  .teklifKutu {
    width: 100%;
    height: auto;
  }

  .teklifImage img {
    width: 100%;
    height: auto;
  }

  .teklifButon button {
    width: 100%;
    padding: 12px;
    font-size: 1.1em;
    margin: 10px 0;
  }

  /* Neden bizimle çalışmalısınız bölümü */
  .whyWorkContainer {
    width: 100%;
    padding: 0px;
  }

  .whyWork {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    padding: 0px;
  }

  .workCard {
    width: 100%;
  }

  .workCard img {
    width: 100%;
    height: auto;
  }
}

/* Daha küçük ekranlar için ek düzenlemeler */
@media screen and (max-width: 480px) {
  .sirketLogololari {
    grid-template-columns: repeat(2, 1fr);
  }

  .teklifBaslik h1 {
    font-size: 1.3em;
  }

  .basliklar p {
    font-size: 1.1em;
  }

  .hizmetler li {
    font-size: 0.9em;
  }

  .teklifButon button {
    font-size: 1em;
  }
}

.kampanyaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.kampanyaContent {
  width: 70%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.kampanyaResim {
  width: 40%;
  padding-right: 20px;
  
}

.kampanyaResim img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
  margin-top: 10px;
}

.kampanyaAciklama {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.kampanyaAciklama h2 {
  color: #292929;
  font-family: "Sora", sans-serif;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.kampanyaAciklama p {
  color: #292929;
  font-family: "Sora", sans-serif;
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.kampanyaButton {
  background-color: transparent;
  color: #FF6B00;
  border: none;
  padding: 12px 25px 12px 0px;
  font-family: "Sora", sans-serif;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 5px;
  width: fit-content;
}

.kampanyaButton:hover {
  font-weight: 900;
}

/* Mobil görünüm için medya sorgusu */
@media screen and (max-width: 768px) {
  .kampanyaContent {
    width: 90%;
    flex-direction: column;
    padding: 15px;
  }

  .kampanyaResim {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .kampanyaAciklama {
    width: 100%;
    padding-left: 0;
    text-align: center;
    align-items: center;
  }

  .kampanyaAciklama h2 {
    font-size: 1.5em;
  }

  .kampanyaAciklama p {
    font-size: 1em;
  }
}