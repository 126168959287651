@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Signika+Negative:wght@300..700&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika+Negative:wght@300..700&family=Sora:wght@100..800&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.getAnOfferContainer, 
.inputTitle, 
.inputTitlea,
.offerTitle,
.offerDescription,
.labelFlex,
input,
.date-input,
.inputb {
  font-family: "Sora", sans-serif;
}

input::placeholder,
.date-input::placeholder {
  font-family: "Sora", sans-serif;
}
.offerTitle{
    margin-top: 2px;
    padding-top: 0;
    text-align: center;
}
.getAnOfferContainer{
    width: 70%;
    margin: 100px auto;
    display: flex;
    align-items: stretch;
    justify-content: center;
    min-height: 700px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}
.getAnOffer_right{
    background-color: #e4e4e4;
    width: 50%;
    padding: 30px 20px;
    color: #1F2E8F;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.getAnOffer_left{
    background-color: #1F2E8F;
    padding: 30px 20px;
    width: 50%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.sifir-arac-bilgileri {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* 2 sütun */
  grid-gap: 20px;  /* sütunlar ve satırlar arası boşluk */
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.sifir-arac-bilgileri .ininina {
  width: 100%;
  margin: 0;  /* Önceki margin'i sıfırla */
}

.checkFor {
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 20px 0;
  padding: 10px;
  border-radius: 8px;
}

.labelFlex {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.1em;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.labelFlex:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Sağ taraftaki labellar için hover efekti */
.getAnOffer_right .labelFlex:hover {
  background-color: rgba(31, 46, 143, 0.1);
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  background-color: transparent;
}

/* Radio button seçili durumu */
input[type="radio"]:checked {
  background-color: transparent;
  border: 2px solid #fff;
}

/* Radio button iç nokta */
input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: radioScale 0.2s ease-in-out;
}

/* Sağ taraftaki radio buttonlar için (koyu zemin üzerinde) */
.getAnOffer_right input[type="radio"] {
  border-color: #1F2E8F;
}

.getAnOffer_right input[type="radio"]:checked {
  border-color: #1F2E8F;
}

.getAnOffer_right input[type="radio"]:checked::before {
  background-color: #1F2E8F;
}

/* Hover efekti */
input[type="radio"]:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

/* Radio seçim animasyonu */
@keyframes radioScale {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.inputb {
  width: 100%;
  padding: 10px 0px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  background-color: #fff;
}

.inputTitle {
  margin-bottom: 8px;
  font-weight: 600;
}

.ininin{
    margin: 10px 0;
    width: 100%;
}
.ininina{
    margin: 10px 0;
    width: 100%;
}
.inputContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    
    
}
.teklifbuton > input{
    width: 250px;
    background-color: #1F2E8F;
    color: #fff;
    border: none;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: 1em;
    cursor: pointer;
    
    height: 50px;
    text-align: center;
    flex-direction: column;
    margin-top: 35px;
    padding-left: 0;
}
.teklifbuton > input:hover{
    transition: 0.3s ease-in-out;
    background-color: #fff;
    color: #1F2E8F;
    border: 2px solid #1F2E8F;
}

/* Responsive tasarım için */


/* Mobil cihazlar için responsive tasarım */
@media (max-width: 768px) {
  .sifir-arac-bilgileri {
    grid-template-columns: 1fr;  /* Tek sütun */
    width: 100%;
  }
}

/* Date input için özel stiller */
.date-input {
  position: relative;
  width: 100%;
 
}

/* Takvim ikonunu özelleştirme */
.date-input::-webkit-calendar-picker-indicator {
  filter: invert(19%) sepia(71%) saturate(1954%) hue-rotate(227deg) brightness(94%) contrast(88%);
  cursor: pointer;
  height: 16px;
  padding: 10px;
  position: absolute;
  right: 5px;
  width: 16px;
}

/* Firefox için özel stiller */
.date-input::-moz-calendar-picker-indicator {
  filter: invert(19%) sepia(71%) saturate(1954%) hue-rotate(227deg) brightness(94%) contrast(88%);
  cursor: pointer;
  padding: 10px;
}

/* Edge ve IE için özel stiller */
.date-input::-ms-clear,
.date-input::-ms-reveal {
  display: none;
}

/* Placeholder rengi */
.date-input::placeholder {
  color: #999;
}

/* Hover efekti */
.date-input:hover {
  border-color: #1F2E8F;
}

/* Focus efekti */
.date-input:focus {
  border-color: #1F2E8F;
  box-shadow: 0 0 0 2px rgba(31, 46, 143, 0.2);
  outline: none;
}

/* Checkbox için özel stiller */
input[type="checkbox"] {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
  accent-color: #1F2E8F;
  border: 2px solid #1F2E8F;
  border-radius: 4px;
}

/* Checkbox label için stil */
.labelFlex {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.1em;
  cursor: pointer;
  margin-right: 10px;
}

/* Hover efekti */
input[type="checkbox"]:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
@media  (max-width: 1200px) {
    .getAnOfferContainer {
      width: 85%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .getAnOfferContainer {
      width: 100% !important;
      flex-direction: column !important;
      margin: 30px 0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    
    .getAnOffer_left,
    .getAnOffer_right {
      width: 100% !important;
      padding: 20px 0 !important;
    }
  
    .inputContainer {
      width: 90% !important;
      padding: 0 !important;
      margin: 0 auto !important;
    }
  
    .ininin,
    .ininina,
    .inininS {
      width: 100% !important;
      margin: 3px 0 !important;
      padding: 0 !important;
    }
  
    .inputContainer .inputb {
      width: 100% !important;
      height: 40px !important;
      padding: 0 1px !important;
      margin: 5px 0 !important;
      font-size: 16px !important;
    }
  
    .sifir-arac-bilgileri {
      grid-template-columns: 1fr !important;
      width: 90% !important;
      padding: 10px 0 !important;
      margin: 10px auto !important;
      background-color: transparent !important;
    }
  
    .checkFor {
      width: 90% !important;
      padding: 0 !important;
      margin: 10px auto !important;
    }
  
    .labelFlex {
      padding: 3px 0 !important;
      margin: 0 !important;
    }
  
    .teklifbuton {
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;
    }
  
    .teklifbuton > input {
      width: 90% !important;
      margin: 0px auto !important;
    }
  
    .offerTitle,
    .offerDescription {
      width: 90% !important;
      margin: 0px auto !important;
      text-align: center !important;
    }
  
    .offerTitleContainer,
    .offerTitleContainerA {
      width: 100% !important;
      margin: 10px 0 !important;
    }
  
    .inputTitle {
      width: 100% !important;
      margin-bottom: 5px !important;
      padding: 0 1px !important;
    }
  
    .labelFlex input[type="radio"],
    .labelFlex input[type="checkbox"] {
      margin: 0 0 0 10px !important;
    }
  
    textarea.inputb {
      min-height: 100px !important;
      padding: 0 !important;
    }
  
    .date-input {
      padding-right: 0px !important;
    }
  }

